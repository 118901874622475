// cortexConfig.js

/**
 * cortexConfig is an object describing each "cortex type":
 * - "Custom IOA Rule", "File Vantage Rules", and "NextGen Siem Correlation rule".
 *
 * For each type, either:
 *   - a "fields" array (if there are no sub-rules), OR
 *   - a "subRules" object that has one or more sub-rules. Each sub-rule has its own "fields" array.
 *
 * Each field is specified by:
 *   { label: string, type: "string" | "bool" }
 */
export const cortexConfig = {
    "Custom IOA Rule": {
        subRules: {
            "Process Creation": {
                fields: [
                    { label: "action to take", type: "string" },
                    { label: "Severity", type: "string" },
                    { label: "Rule Name", type: "string" },
                    { label: "Rule description", type: "string" },
                    { label: "Grandparent Image Filename", type: "string" },
                    { label: "Grandparent Command Line", type: "string" },
                    { label: "Parent Image Filename", type: "string" },
                    { label: "Parent Command Line", type: "string" },
                    { label: "Image Filename", type: "string" },
                    { label: "Command Line", type: "string" },
                    { label: "Comment for audit log (Recommended)", type: "string" }
                ]
            },
            "File Creation": {
                fields: [
                    { label: "action to take", type: "string" },
                    { label: "Severity", type: "string" },
                    { label: "Rule Name", type: "string" },
                    { label: "Rule description", type: "string" },
                    { label: "Grandparent Image Filename", type: "string" },
                    { label: "Grandparent Command Line", type: "string" },
                    { label: "Parent Image Filename", type: "string" },
                    { label: "Parent Command Line", type: "string" },
                    { label: "Image Filename", type: "string" },
                    { label: "Command Line", type: "string" },
                    { label: "Comment for audit log (Recommended)", type: "string" },
                    { label: "File Path", type: "string" },
                    { label: "File type", type: "string" },
                ]
            },
            "Network Connection": {
                fields: [
                    { label: "action to take", type: "string" },
                    { label: "Severity", type: "string" },
                    { label: "Rule Name", type: "string" },
                    { label: "Rule description", type: "string" },
                    { label: "Grandparent Image Filename", type: "string" },
                    { label: "Grandparent Command Line", type: "string" },
                    { label: "Parent Image Filename", type: "string" },
                    { label: "Parent Command Line", type: "string" },
                    { label: "Image Filename", type: "string" },
                    { label: "Command Line", type: "string" },
                    { label: "Comment for audit log (Recommended)", type: "string" },
                    { label: "Remote IP Address", type: "string" },
                    { label: "Remote TCP/UDP Port", type: "string" },
                    { label: "ICMP", type: "bool" },
                    { label: "TCP", type: "bool" },
                    { label: "UDP", type: "bool" }
                ]
            },
            "Domain name": {
                fields: [
                    { label: "action to take", type: "string" },
                    { label: "Severity", type: "string" },
                    { label: "Rule Name", type: "string" },
                    { label: "Rule description", type: "string" },
                    { label: "Grandparent Image Filename", type: "string" },
                    { label: "Grandparent Command Line", type: "string" },
                    { label: "Parent Image Filename", type: "string" },
                    { label: "Parent Command Line", type: "string" },
                    { label: "Image Filename", type: "string" },
                    { label: "Command Line", type: "string" },
                    { label: "Comment for audit log (Recommended)", type: "string" },
                    { label: "Domain Name", type: "string" }
                ]
            }
        }
    },

    "File Vantage Rules": {
        subRules: {
            "File Rule": {
                fields: [
                    { label: "Rule Path", type: "string" },
                    { label: "Depth", type: "string" },
                    { label: "Severity", type: "string" },
                    { label: "Description (optional)", type: "string" },

                    // Directory as a single-select with these string options
                    {
                        label: "Directory",
                        type: "select",
                        options: ["All", "Create", "Delete", "Rename", "Permissions", "Attributes"]
                    },

                    // File as a single-select
                    {
                        label: "File",
                        type: "select",
                        options: ["All", "Create", "Write", "Delete", "Rename", "Permissions", "Attributes"]
                    },

                    // Monitor changes in Files and folders -> single-select "include all"/"include only"
                    {
                        label: "Monitor changes in Files and folders",
                        type: "select",
                        options: ["include all", "include only"]
                    },

                    // Processes -> single-select "include all"/"include only"
                    {
                        label: "Processes",
                        type: "select",
                        options: ["include all", "include only"]
                    },

                    // Write changes: "Allow Crowdstrike to capture file content changes" -> bool
                    { label: "Allow Crowdstrike to capture file content changes", type: "bool" },

                    // file names -> string
                    { label: "file names", type: "string" },

                    // capture hashes for all written files -> bool
                    { label: "capture hashes for all written files", type: "bool" }
                ]
            },
            "Registry Rule": {
                fields: [
                    { label: "Rule Path", type: "string" },
                    { label: "Depth", type: "string" },
                    { label: "Severity", type: "string" },
                    { label: "Description (optional)", type: "string" },

                    // Key as a single-select
                    {
                        label: "Key",
                        type: "select",
                        options: ["All", "Create", "Delete", "Rename", "Permissions"]
                    },

                    // Value as a single-select
                    {
                        label: "Value",
                        type: "select",
                        options: ["All", "Set", "Delete"]
                    },

                    // Monitor changes in Keys and values -> single-select
                    {
                        label: "Monitor changes in Keys and values",
                        type: "select",
                        options: ["include all", "include only"]
                    },

                    // Processes -> single-select
                    {
                        label: "Processes",
                        type: "select",
                        options: ["include all", "include only"]
                    },

                    // Write changes -> bool
                    { label: "Allow Crowdstrike to capture registry content changes", type: "bool" },

                    // Registry Values -> string
                    { label: "Registry Values", type: "string" }
                ]
            }
        }
    },


    "NextGen Siem Correlation rule": {
        // No subRules here, just a direct fields array
        fields: [
            { label: "Name", type: "string" },
            { label: "Description", type: "string" },
            { label: "Search Query", type: "string" },

            // TriggerType: "Verbox/Summery" 
            {
                label: "TriggerType",
                type: "select",
                options: ["Verbox", "Summery"]
            },

            // Outcome: "Incident/Detection"
            {
                label: "Outcome",
                type: "select",
                options: ["Incident", "Detection"]
            },

            // Severity: "informational/low/medium/high/critical"
            {
                label: "Severity",
                type: "select",
                options: ["informational", "low", "medium", "high", "critical"]
            },

            // MITRE ATTACK Tactic&Technique is a checkbox
            { label: "MITRE ATTACK Tactic&Technique", type: "bool" },

            // matrix: "Enterprise/Mobile"
            {
                label: "matrix",
                type: "select",
                options: ["Enterprise", "Mobile"]
            },

            { label: "tactic", type: "string" },
            { label: "Technique", type: "string" }
        ]
    }

};
