import React, { useState, useEffect } from 'react';
import {
  TextField,
  Button,
  MenuItem,
  Typography,
  Container,
  Grid,
  Paper,
  Autocomplete,
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { withRouter } from 'react-router-dom'; // Import withRouter HOC
import sdk from 'api-sdk';
import { toast } from 'react-toastify';

// Create a dark theme
const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: { main: '#90caf9' },
    background: { paper: '#121212', default: '#303030' },
  },
  typography: { h4: { color: '#ffffff' } },
});

const EditScenario = ({ match, history, location }) => {
  const id = match.params.id; // Get scenario ID from route params

  const [form, setForm] = useState({
    silverBulletId: '',
    silverBulletName: '',
    scenarioDifficulty: '',
    scenarioImpact: '',
    tactic: '',
    description: '',
    status: 'Pending',
  });

  const [silverBullets, setSilverBullets] = useState([]);
  const [selectedBullet, setSelectedBullet] = useState(null);
  const [errors, setErrors] = useState({});
  const [lightChartFile, setLightChartFile] = useState(null);

  useEffect(() => {
    if (location.state && location.state.scenario) {
      setForm(location.state.scenario);
      setSelectedBullet({
        id: location.state.scenario.silverBulletId,
        name: location.state.scenario.silverBulletName,
      });
    } else {
      fetchScenarioData();
    }
    fetchSilverBullets();
  }, [id]);

  const fetchScenarioData = async () => {
    try {
      const response = await sdk.scenario.getById(id);
      setForm(response);
      setSelectedBullet({
        id: response.silverBulletId,
        name: response.silverBulletName,
      });
    } catch (error) {
      console.error('Error fetching scenario:', error);
      toast.error('Failed to fetch scenario data.');
    }
  };

  const fetchSilverBullets = async () => {
    try {
      const response = await sdk.silverBullets.get();
      setSilverBullets(response);
    } catch (error) {
      console.error('Error fetching SilverBullets:', error);
    }
  };

  const difficultyOptions = ['Low', 'Medium', 'High', 'נמוך', 'בינוני', 'גבוה'];
  const impactOptions = ['Low', 'Medium', 'High', 'נמוך', 'בינוני', 'גבוה'];

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleBulletChange = (event, newValue) => {
    setSelectedBullet(newValue);
    if (newValue) {
      setForm({
        ...form,
        silverBulletId: newValue.id,
        silverBulletName: newValue.name,
      });
    }
  };

  const validateForm = () => {
    let newErrors = {};
    if (!form.silverBulletId) newErrors.silverBulletId = 'SilverBullet ID is required';
    if (!form.silverBulletName) newErrors.silverBulletName = 'SilverBullet name is required';
    if (!form.scenarioDifficulty) newErrors.scenarioDifficulty = 'Difficulty is required';
    if (!form.scenarioImpact) newErrors.scenarioImpact = 'Impact is required';
    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    try {
      await sdk.scenario.update(id, form);
      if (lightChartFile) {
        await sdk.silverBullets.uploadFile(lightChartFile, form.silverBulletId, 'lightchart');
      }
      toast.success('Scenario updated successfully!');
      history.push('/');
    } catch (error) {
      console.error('Error updating scenario:', error);
      toast.error('Failed to update scenario.');
    }
  };

  return (
    <ThemeProvider theme={darkTheme}>
      <Container maxWidth="md">
        <Paper elevation={3} style={{ padding: '2rem', marginTop: '2rem' }}>
          <Typography variant="h4" gutterBottom>
            Edit Scenario
          </Typography>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Autocomplete
                  fullWidth
                  options={silverBullets}
                  getOptionLabel={(option) => option.name || ''}
                  value={selectedBullet}
                  onChange={handleBulletChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="SilverBullet Name"
                      error={!!errors.silverBulletName}
                      helperText={errors.silverBulletName || ''}
                      required
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="SilverBullet ID"
                  name="silverBulletId"
                  value={form.silverBulletId}
                  onChange={handleChange}
                  disabled
                  required
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  select
                  fullWidth
                  label="Scenario Difficulty"
                  name="scenarioDifficulty"
                  value={form.scenarioDifficulty}
                  onChange={handleChange}
                  required
                >
                  {difficultyOptions.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  select
                  fullWidth
                  label="Scenario Impact"
                  name="scenarioImpact"
                  value={form.scenarioImpact}
                  onChange={handleChange}
                  required
                >
                  {impactOptions.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Tactic"
                  name="tactic"
                  value={form.tactic}
                  onChange={handleChange}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Description"
                  name="description"
                  value={form.description}
                  onChange={handleChange}
                  multiline
                  rows={4}
                />
              </Grid>

              <Grid item xs={12}>
                <input
                  accept="image/*"
                  style={{ display: 'none' }}
                  id="light-chart-upload"
                  type="file"
                  onChange={(e) => setLightChartFile(e.target.files[0])}
                />
                <label htmlFor="light-chart-upload">
                  <Button variant="contained" component="span">
                    Upload Light Mode Chart
                  </Button>
                  {lightChartFile && (
                    <Typography variant="body2" style={{ marginTop: '8px' }}>
                      {lightChartFile.name}
                    </Typography>
                  )}
                </label>
              </Grid>

              <Grid item xs={12}>
                <Button type="submit" fullWidth variant="contained" color="primary">
                  Update Scenario
                </Button>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Container>
    </ThemeProvider>
  );
};

export default withRouter(EditScenario);
