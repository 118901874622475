import React from "react";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  Jumbotron,
  Row,
  Col,
  CardTitle,
} from "reactstrap";
import classnames from "classnames";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

class BlueRule extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: "1", // Default to the Description tab
    };

    this.toggleTab = this.toggleTab.bind(this);
  }

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({ activeTab: tab });
    }
  }

  renderSysmonContent(sysmon) {
    return (
      <div>
        <h4>Sysmon Configuration</h4>
        <pre
          style={{
            backgroundColor: "#343a40",
            color: "#f8f9fa",
            padding: "1rem",
            borderRadius: "0.5rem",
          }}
        >
          {sysmon.xmlText}
        </pre>
      </div>
    );
  }

  renderCortexContent(cortex) {
    // Separate fields with true bool_value and other fields
    const trueBoolFields = cortex.filter((entry) => entry.bool_value === true);
    const otherFields = cortex.filter((entry) => entry.bool_value !== true);

    return (
      <div>
        <h4>Cortex Rules</h4>
        <ul className="list-unstyled">
          {/* Render other fields first */}
          {otherFields.map((entry) => (
            <li key={entry.id} className="mb-2">
              <strong>{entry.fields}:</strong>{" "}
              {entry.string_value || entry.bool_value.toString()}
            </li>
          ))}
          {/* Render fields with true bool_value as a checkbox after the text */}
          {trueBoolFields.length > 0 && (
            <div className="mt-4">
              <h5>Boolean Fields</h5>
              {trueBoolFields.map((entry) => (
                <li key={entry.id} className="mb-2">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked
                        readOnly
                        sx={{
                          "& .MuiSvgIcon-root": { fontSize: 16 }, // Adjust fontSize for smaller checkbox
                        }}
                      />
                    }
                    label={
                      <span style={{ fontSize: "0.875rem" }}>{entry.fields}</span> // Adjust label text size
                    }
                  />
                </li>
              ))}
            </div>
          )}
        </ul>
      </div>
    );
  }

  render() {
    const { rule, closeFunc } = this.props;
    const { activeTab } = this.state;

    // Prepare dynamic tabs for defense systems
    const defenseSystems = [];
    if (rule.sysmon && rule.sysmon.length > 0) {
      defenseSystems.push({
        id: "sysmon",
        label: "Sysmon",
        content: this.renderSysmonContent(rule.sysmon[0]),
      });
    }
    if (rule.cortex && rule.cortex.length > 0) {
      defenseSystems.push({
        id: "cortex",
        label: "Cortex",
        content: this.renderCortexContent(rule.cortex),
      });
    }

    return (
      <div className="mt-4">
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "1" })}
              onClick={() => this.toggleTab("1")}
            >
              Description
            </NavLink>
          </NavItem>
          {defenseSystems.map((system, index) => (
            <NavItem key={system.id}>
              <NavLink
                className={classnames({ active: activeTab === (index + 2).toString() })}
                onClick={() => this.toggleTab((index + 2).toString())}
              >
                {system.label}
              </NavLink>
            </NavItem>
          ))}
          <NavItem>
            <NavLink onClick={closeFunc}>
              <i className="fas fa-times" />
            </NavLink>
          </NavItem>
        </Nav>
        <Card body className="pt-4 pb-4">
          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              <Row>
                <Col md="10">
                  <CardTitle tag="h1">{rule.name}</CardTitle>
                </Col>
              </Row>
              <h2 className="font-light mb-3">Rule Category: {rule.ruleCategory}</h2>
              <h4 className="font-light mb-2">
                Creator: {rule.creator ? rule.creator : "Red Team"}
              </h4>
              <Jumbotron className="pt-4 pb-4">
                <h4>{rule.description}</h4>
              </Jumbotron>
            </TabPane>
            {defenseSystems.map((system, index) => (
              <TabPane key={system.id} tabId={(index + 2).toString()}>
                {system.content}
              </TabPane>
            ))}
          </TabContent>
        </Card>
      </div>
    );
  }
}

export default BlueRule;
