import React from "react";
import {
    Button,
    Card,
    CardBody,
    CardTitle,
    Col,
    CustomInput,
    Form,
    FormFeedback,
    FormGroup,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Row,
    UncontrolledAlert,
    Nav,
    NavItem,
    NavLink
} from 'reactstrap';
import { Link } from 'react-router-dom';
import sdk from 'api-sdk';                // <--- Using your existing "api-sdk" index
// or if you have a dedicated file for cortex, do:
// import cortex from 'api-sdk/cortex';
import categories from '../../assets/data/attackVectors.json';
import platformsData from '../../assets/data/platformsBlueRule.json';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import classnames from 'classnames';
import AddCortex from "../AddCortex/AddCortex";
import AddSysmon from "../AddSysmon/AddSysmon";

// Extract the rule categories from the categories file
const ruleCategories = categories.map(category => category.title);

class BlueRuleCreation extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            creating: true,
            uploadError: undefined,
            ruleCategory: undefined,
            ruleCategoryError: undefined,
            status: undefined,
            statusError: undefined,
            creator: undefined,
            creatorError: undefined,
            ruleName: undefined,
            nameError: undefined,
            description: undefined,
            descriptionError: undefined,

            // Files
            playbookFile: undefined,
            playbookFileError: undefined,
            chartFile: undefined,

            chosenPlatforms: {},

            // We store the DS data here
            cortexData: null,
            sysmonData: null,
            //tabs
            activeTab: '1',
            silverBullets: [],
            selectedSilverBullet: null,
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleCortexChange = this.handleCortexChange.bind(this);
        this.toggleTab = this.toggleTab.bind(this);
        this.handleSysmonChange = this.handleSysmonChange.bind(this);
        this.handleCategoryChange = this.handleCategoryChange.bind(this);
    }

    handleCortexChange(cortexData) {
        // cortexData = { cortexType, cortexSubRule, fieldValues }
        this.setState({ cortexData });
    }

    handleSysmonChange(xmlText) {
        this.setState({ sysmonData: xmlText });
    }

    handleCategoryChange = async (e) => {
        const selectedCategory = e.target.value.trim();  // Trim spaces and new lines
        this.setState({ ruleCategory: selectedCategory, ruleCategoryError: undefined, silverBullets: [], creating: true });
        console.log(selectedCategory);

        try {
            const bullets = await sdk.silverBullets.getByAttackVector(selectedCategory);
            console.log(bullets);

            this.setState({ silverBullets: bullets, creating: false });
        } catch (error) {
            toast.error("Failed to fetch SilverBullets.");
            this.setState({ creating: false });
        }
    };




    toggleTab(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({ activeTab: tab });
        }
    }

    validateForm() {
        const errors = {};

        if (!this.state.ruleName) {
            errors.nameError = 'Rule Name required';
        }

        if (!this.state.ruleCategory) {
            errors.ruleCategoryError = 'Rule Category required';
        }

        if (!this.state.status) {
            errors.statusError = 'Status required';
        }

        if (!this.state.creator) {
            errors.creatorError = 'Creator required';
        }

        if (!this.state.description) {
            errors.descriptionError = 'Description required';
        }

        if (!this.state.playbookFile) {
            errors.playbookFileError = 'Playbook file required';
        }

        return {
            isValid: Object.keys(errors).length === 0,
            errors
        };
    }

    handleSubmit(e) {
        e.preventDefault();
        const { isValid, errors } = this.validateForm();
        if (!isValid) {
            this.setState(errors);
            return;
        }

        // Build the new BlueRule object
        const newBlueRule = {
            name: this.state.ruleName,
            ruleCategory: this.state.ruleCategory,
            description: this.state.description,
            status: this.state.status,
            creator: this.state.creator,
            silverBulletId: this.state.selectedSilverBullet ? this.state.selectedSilverBullet : null  // Set to null if not selected
        };


        this.setState({ creating: true });

        // 1) Create the BlueRule
        sdk.blueRules.create(newBlueRule).then(rule => {
            // We'll gather all promises in an array so we can do Promise.all
            const promises = [];

            // 2) Upload the playbook file
            if (this.state.playbookFile) {
                promises.push(
                    sdk.blueRules.uploadFile(this.state.playbookFile, rule.id, 'playbook')
                );
            }

            // 3) Upload chart if present
            if (this.state.chartFile) {
                promises.push(
                    sdk.blueRules.uploadFile(this.state.chartFile, rule.id, 'chart')
                );
            }

            // 4) Chosen platforms
            if (this.state.chosenPlatforms) {
                const updatedChosenPlatforms = {
                    ...this.state.chosenPlatforms,
                    blueRuleId: rule.id
                };
                promises.push(sdk.blueRulesPlatforms.create(updatedChosenPlatforms));
            }

            // 5) Create the cortex rows (if user filled AddCortex data)
            if (this.state.cortexData) {
                // This object has: { cortexType, cortexSubRule, fieldValues }
                // We'll convert fieldValues into an array of rows
                const { cortexType, cortexSubRule, fieldValues } = this.state.cortexData;

                const entries = Object.entries(fieldValues);
                // e.g. [ ["action to take", "Block"], ["Severity", "High"], ["TCP", true] ]

                if (entries.length > 0) {
                    const cortexRows = entries.map(([label, val]) => {
                        const isBool = typeof val === 'boolean';
                        return {
                            // "fields" column
                            fields: label,
                            // "string_value" if it's not a bool
                            string_value: isBool ? null : val,
                            // "bool_value" if it is a bool
                            bool_value: isBool ? val : null,
                            // "rule_type" is the subrule or type 
                            rule_type: cortexSubRule || cortexType,
                            // "rule_id" the newly created BlueRule ID
                            rule_id: rule.id
                        };
                    });

                    // For each row, we do sdk.cortex.create(...)
                    // (Or you might have a batch endpoint, but let's do one by one)
                    cortexRows.forEach(cortexRow => {
                        promises.push(sdk.cortex.create(cortexRow));
                    });
                }
            }

            if (this.state.sysmonData) {
                promises.push(
                    sdk.sysmon.create({
                        xmlText: this.state.sysmonData,
                        rule_id: rule.id
                    })
                );
            }

            // Return the Promise.all with everything
            return Promise.all(promises);
        }).then(() => {
            toast.success("Blue Rule Created Successfully");
            // Redirect after short delay
            setTimeout(() => {
                this.props.history.push('/blue-rule-management');
            }, 2000);
        }).catch(e => {
            this.setState({
                creating: false,
                uploadError: e.toString()
            });
        });
    }

    componentDidMount() {
        this.setState({ creating: false });
    }

    render() {
        const platforms = platformsData;

        return (
            <div>
                <ToastContainer />
                <Form onSubmit={this.handleSubmit}>
                    <Card>
                        <CardTitle className="bg-light border-bottom p-3 mb-0">
                            Create a Blue Rule
                            <span className="float-right">
                                <Link to={`/blue-rule-management`} onClick={e => e.stopPropagation()}>
                                    <Button className='btn-outline-danger' size='sm'>
                                        <i className='fas fa-times' />
                                    </Button>
                                </Link>
                            </span>
                        </CardTitle>
                        <CardBody>
                            <Row>
                                <Col md='6'>
                                    <Row>
                                        <Col md='6'>
                                            <FormGroup>
                                                <InputGroup>
                                                    <InputGroupAddon addonType="prepend">
                                                        <InputGroupText>Rule Name</InputGroupText>
                                                    </InputGroupAddon>
                                                    <Input
                                                        type="text"
                                                        disabled={this.state.creating}
                                                        invalid={!!this.state.nameError}
                                                        onChange={e =>
                                                            this.setState({ ruleName: e.target.value, nameError: undefined })
                                                        }
                                                    />
                                                    <FormFeedback>
                                                        {this.state.nameError}
                                                    </FormFeedback>
                                                </InputGroup>
                                            </FormGroup>
                                        </Col>
                                        <Col md='6'>
                                            <FormGroup>
                                                <InputGroup>
                                                    <Input
                                                        type="select"
                                                        disabled={this.state.creating}
                                                        invalid={!!this.state.statusError}
                                                        onChange={e => this.setState({ status: e.target.value, statusError: undefined })}
                                                        defaultValue="Select Status"
                                                    >
                                                        <option key='status-placeholder' default disabled>Select Status</option>
                                                        <option>online</option>
                                                        <option>offline</option>
                                                    </Input>
                                                    <FormFeedback>
                                                        {this.state.statusError}
                                                    </FormFeedback>
                                                </InputGroup>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md='6'>
                                            <FormGroup>
                                                <InputGroup>
                                                    <Input
                                                        type="select"
                                                        disabled={this.state.creating}
                                                        invalid={!!this.state.ruleCategoryError}
                                                        onChange={this.handleCategoryChange}  // Call function to fetch Silver Bullets
                                                        defaultValue="Select Rule Category"
                                                    >
                                                        <option key='ruleCategory-placeholder' default disabled>Select Rule Category</option>
                                                        {ruleCategories.map(category => (
                                                            <option key={category + '-option'} value={category}>{category}</option>
                                                        ))}
                                                    </Input>
                                                    <FormFeedback>
                                                        {this.state.ruleCategoryError}
                                                    </FormFeedback>
                                                </InputGroup>
                                            </FormGroup>
                                            <FormGroup>
                                                <InputGroup>
                                                    <Input
                                                        type="select"
                                                        disabled={this.state.creating || this.state.silverBullets.length === 0}
                                                        value={this.state.selectedSilverBullet || ""}
                                                        onChange={(e) =>
                                                            this.setState({ selectedSilverBullet: e.target.value || null })
                                                        }
                                                    >
                                                        <option value="">Select a SilverBullet (Optional)</option>
                                                        {this.state.silverBullets.map((bullet) => (
                                                            <option key={bullet.id} value={bullet.id}>
                                                                {bullet.name}
                                                            </option>
                                                        ))}
                                                    </Input>
                                                </InputGroup>
                                            </FormGroup>

                                            {/* Add the no Silver Bullet message here */}
                                            {this.state.silverBullets.length === 0 && this.state.ruleCategory && (
                                                <div className="text-danger mt-2">No Silver Bullets available for the selected category.</div>
                                            )}
                                        </Col>
                                        <Col md="6">
                                            <CardTitle className="bg-light border-bottom p-3 mt-3">
                                                Creator
                                            </CardTitle>
                                            <FormGroup>
                                                <Input
                                                    type="select"
                                                    className="form-control"
                                                    value={this.state.creator}
                                                    onChange={e => this.setState({ creator: e.target.value, creatorError: undefined })}
                                                    invalid={!!this.state.creatorError}
                                                >
                                                    <option value="">Select a Creator</option>
                                                    <option value="Michael Reder">Michael Reder</option>
                                                    <option value="Noa Elias">Noa Elias</option>
                                                    <option value="Yoav Laugie">Yoav Laugie</option>
                                                    <option value="Dylan Fleury">Dylan Fleury</option>
                                                    <option value="Ury Yudkin">Ury Yudkin</option>
                                                    <option value="Danel Yehuda">Danel Yehuda</option>
                                                </Input>
                                                <FormFeedback>
                                                    {this.state.creatorError}
                                                </FormFeedback>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <FormGroup className='mb-0'>
                                                <InputGroup>
                                                    <Input
                                                        className="mt-3"
                                                        type="textarea"
                                                        rows="6"
                                                        placeholder="Rule description..."
                                                        disabled={this.state.creating}
                                                        invalid={!!this.state.descriptionError}
                                                        onChange={e => this.setState({ description: e.target.value, descriptionError: undefined })}
                                                    />
                                                    <FormFeedback>
                                                        {this.state.descriptionError}
                                                    </FormFeedback>
                                                </InputGroup>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col md='6'>
                                    <CardTitle className="bg-light border-bottom p-3">
                                        Select the relevant Platforms
                                    </CardTitle>
                                    <FormGroup>
                                        {platforms.map(platform => (
                                            <div className="form-check form-check-inline" key={platform.name}>
                                                <CustomInput
                                                    type="checkbox"
                                                    id={platform.name}
                                                    label={platform.name}
                                                    onChange={e => {
                                                        const chosenPlatforms = { ...this.state.chosenPlatforms };
                                                        chosenPlatforms[platform.propName] = e.target.checked;
                                                        this.setState({ chosenPlatforms });
                                                    }}
                                                />
                                            </div>
                                        ))}
                                    </FormGroup>
                                </Col>
                            </Row>

                            <Row className="mt-3 ml-1">
                                <Nav tabs>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: this.state.activeTab === '1' })}
                                            onClick={() => this.toggleTab('1')}
                                        >
                                            Sysmon
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: this.state.activeTab === '2' })}
                                            onClick={() => this.toggleTab('2')}
                                        >
                                            CrowdStrike
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                            </Row>
                            <Row className="mt-3">

                                <Col md="12">
                                    {this.state.activeTab === '1' && <AddSysmon onSysmonChange={this.handleSysmonChange} />}
                                    {this.state.activeTab === '2' && <AddCortex onChange={this.handleCortexChange} />}
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>

                    <Row>
                        <Col md='6'>
                            <FormGroup>
                                <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>Playbook HTML file</InputGroupText>
                                    </InputGroupAddon>
                                    <div className="custom-file">
                                        <Input
                                            type="file"
                                            className="custom-file-input"
                                            id="playbookFileInput"
                                            disabled={this.state.creating}
                                            onChange={(e) => this.setState({ playbookFile: e.target.files[0], playbookFileError: undefined })}
                                        />
                                        <label
                                            className="custom-file-label"
                                            htmlFor="playbookFileInput"
                                        >
                                            {this.state.playbookFile ? this.state.playbookFile.name : "Choose file"}
                                        </label>
                                    </div>
                                </InputGroup>
                                <h6 className='text-danger font-light'>
                                    {this.state.playbookFileError}
                                </h6>
                            </FormGroup>
                        </Col>
                        <Col md='6'>
                            <FormGroup>
                                <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>Chart png file</InputGroupText>
                                    </InputGroupAddon>
                                    <div className="custom-file">
                                        <Input
                                            type="file"
                                            className="custom-file-input"
                                            id="chartFileInput"
                                            disabled={this.state.creating}
                                            onChange={(e) => this.setState({ chartFile: e.target.files[0] })}
                                        />
                                        <label
                                            className="custom-file-label"
                                            htmlFor="chartFileInput"
                                        >
                                            {this.state.chartFile ? this.state.chartFile.name : "Choose file"}
                                        </label>
                                    </div>
                                </InputGroup>
                            </FormGroup>
                        </Col>
                    </Row>

                    {this.state.uploadError ? (
                        <UncontrolledAlert className="mt-2 mb-0" color="danger">
                            {this.state.uploadError}
                        </UncontrolledAlert>
                    ) : null}

                    <Row className="mt-2">
                        <Col xl={{ size: 2, offset: 5 }} lg={{ size: 4, offset: 4 }} md='12'>
                            <FormGroup>
                                <Button type="submit" color="secondary" size="lg" block disabled={this.state.creating}>
                                    {!this.state.creating ? "Submit" : <i className="fas fa-spin fa-spinner" />}
                                </Button>
                            </FormGroup>
                        </Col>
                    </Row>
                </Form>
            </div>
        );
    }
}

export default BlueRuleCreation;
