import { request, requestDownload } from './client';
var model = 'eye-of-the-enemy/BlueRules';
var blueRules = {
  get: function get(filter) {
    return request('get', "/".concat(model), null, {
      filter: filter
    });
  },
  getById: function getById(id, filter) {
    return request('get', "/".concat(model, "/").concat(id), null, {
      filter: filter
    });
  },
  create: function create(rule) {
    return request('post', "/".concat(model), rule);
  },
  update: function update(id, rule) {
    return request('patch', "/".concat(model, "/").concat(id), rule);
  },
  delete: function _delete(id) {
    return request('delete', "/".concat(model, "/").concat(id));
  },
  // If you upload files (like playbook or chart):
  uploadFile: function uploadFile(file, blueRuleId, fileType) {
    // eslint-disable-next-line no-undef
    var formData = new FormData();
    formData.append('file', file);
    var config = {
      headers: {
        'content-type': 'multipart/form-data'
      }
    };
    return request('post', "/".concat(model, "/").concat(blueRuleId, "/document?docType=").concat(fileType), formData, config);
  },
  // If you need to get the chart as base64:
  getChartBase64: function getChartBase64(blueRuleId) {
    return request('get', "/".concat(model, "/").concat(blueRuleId, "/chart/base64"));
  },
  // If you need a direct chart download:
  getChartLink: function getChartLink(blueRuleId) {
    return requestDownload("/".concat(model, "/").concat(blueRuleId, "/chart"));
  },
  // Get playbook
  getPlaybook: function getPlaybook(blueRuleId) {
    return request('get', "/".concat(model, "/").concat(blueRuleId, "/playbook"));
  }
};
export default blueRules;