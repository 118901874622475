// AddCortex.jsx
import React, { useState } from 'react';
import {
  FormGroup,
  Label,
  Input,
  CustomInput,
  Row,
  Col
} from 'reactstrap';
import { cortexConfig } from '../../assets/data/cortexConfig';

/**
 * A functional component that:
 * - Renders a dropdown for the user to pick a "cortex type" (e.g. "Custom IOA Rule").
 * - If that type has sub-rules, shows another dropdown for the user to pick one (e.g. "Process Creation").
 * - Dynamically shows relevant fields (text inputs, checkboxes, or dropdowns).
 * - Calls onChange(...) with the entire data each time the user changes anything.
 */
function AddCortex({ onChange }) {
  // Hook states
  const [cortexType, setCortexType] = useState("");
  const [cortexSubRule, setCortexSubRule] = useState("");
  const [fieldValues, setFieldValues] = useState({});

  // 1) Determine the chosen config from the user's selected type
  const chosenConfig = cortexConfig[cortexType] || {};

  // 2) Are there sub-rules? If so, build a list
  const subRuleOptions = chosenConfig.subRules
    ? Object.keys(chosenConfig.subRules)
    : [];

  // 3) Figure out which fields to display
  let currentFields = [];
  if (chosenConfig.fields) {
    // e.g. NextGen SIEM or any type that directly has "fields"
    currentFields = chosenConfig.fields;
  } else if (chosenConfig.subRules && cortexSubRule) {
    // For types with subRules
    const subRuleDef = chosenConfig.subRules[cortexSubRule];
    if (subRuleDef && subRuleDef.fields) {
      currentFields = subRuleDef.fields;
    } else {
      currentFields = [];
    }
  }

  // 4) Helper to notify parent of changes
  const propagateChanges = (updatedFieldValues) => {
    if (onChange) {
      onChange({
        cortexType,
        cortexSubRule,
        fieldValues: updatedFieldValues
      });
    }
  };

  // 5) Handlers
  const handleCortexTypeChange = (e) => {
    const newType = e.target.value;
    // reset sub-rule & fieldValues
    setCortexType(newType);
    setCortexSubRule("");
    setFieldValues({});
    propagateChanges({});
  };

  const handleSubRuleChange = (e) => {
    const newSubRule = e.target.value;
    // reset field values
    setCortexSubRule(newSubRule);
    setFieldValues({});
    propagateChanges({});
  };

  const handleFieldChange = (label, fieldType, newValue) => {
    setFieldValues((prev) => {
      const updated = { ...prev, [label]: newValue };
      propagateChanges(updated);
      return updated;
    });
  };

  return (
    <div>
      <Row form>
        <Col md="6">
          <FormGroup>
            <Label for="cortexTypeSelect">Cortex Type</Label>
            <Input
              type="select"
              id="cortexTypeSelect"
              value={cortexType}
              onChange={handleCortexTypeChange}
            >
              <option value="">-- Select CrowdStrike Type --</option>
              {Object.keys(cortexConfig).map(type => (
                <option key={type} value={type}>
                  {type}
                </option>
              ))}
            </Input>
          </FormGroup>
        </Col>

        {/* sub-rule dropdown if subRules exist */}
        {subRuleOptions.length > 0 && (
          <Col md="6">
            <FormGroup>
              <Label for="cortexSubRuleSelect">Rule Type</Label>
              <Input
                type="select"
                id="cortexSubRuleSelect"
                value={cortexSubRule}
                onChange={handleSubRuleChange}
                disabled={!cortexType}
              >
                <option value="">-- Select Rule Type --</option>
                {subRuleOptions.map(sr => (
                  <option key={sr} value={sr}>
                    {sr}
                  </option>
                ))}
              </Input>
            </FormGroup>
          </Col>
        )}
      </Row>

      {currentFields.length > 0 && (
        <div className="mt-3">
          <h5>
            Fields for: {cortexType}
            {cortexSubRule ? ` → ${cortexSubRule}` : ""}
          </h5>

          {currentFields.map(fieldDef => {
            const { label, type, options } = fieldDef;
            // Retrieve previously selected/stored value
            const rawValue = fieldValues[label];
            const value = (rawValue !== undefined && rawValue !== null)
              ? rawValue
              : (type === "bool" ? false : "");

            // 1) Checkbox (bool)
            if (type === "bool") {
              return (
                <FormGroup check key={label}>
                  <CustomInput
                    id={`fieldCheck-${label}`}
                    type="checkbox"
                    label={label}
                    checked={!!value}
                    onChange={(e) => handleFieldChange(label, type, e.target.checked)}
                  />
                </FormGroup>
              );
            }
            // 2) Select (dropdown)
            else if (type === "select" && Array.isArray(options)) {
              return (
                <FormGroup key={label}>
                  <Label>{label}</Label>
                  <Input
                    type="select"
                    value={value}
                    onChange={(e) => handleFieldChange(label, type, e.target.value)}
                  >
                    <option value="">-- select an option --</option>
                    {options.map(opt => (
                      <option key={opt} value={opt}>
                        {opt}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
              );
            }
            // 3) Default: text input
            else {
              return (
                <FormGroup key={label}>
                  <Label>{label}</Label>
                  <Input
                    type="text"
                    value={value}
                    onChange={(e) => handleFieldChange(label, type, e.target.value)}
                  />
                </FormGroup>
              );
            }
          })}
        </div>
      )}
    </div>
  );
}

export default AddCortex;
