import React from "react";
import {
  Card,
  CardTitle,
  CardSubtitle,
  Col,
  Row,
  ButtonGroup,
  Button,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMicrosoft, faLinux, faAws } from "@fortawesome/free-brands-svg-icons";
import Icon from "@mdi/react";
import { mdiMicrosoftAzure, mdiGoogleCloud } from "@mdi/js";
import sdk from "api-sdk";
import BlueRule from "../BlueRule"; // Import the detailed BlueRule component
import "./BlueRuleMenu.css";

const Platforms = [
  { name: "Windows", key: "windows", platform: <FontAwesomeIcon icon={faMicrosoft} /> },
  { name: "AWS", key: "aws", platform: <FontAwesomeIcon icon={faAws} /> },
  { name: "Azure", key: "azure", platform: <Icon path={mdiMicrosoftAzure} size={2} /> },
  { name: "GCP", key: "gcp", platform: <Icon path={mdiGoogleCloud} size={2} /> },
  { name: "Linux", key: "linux", platform: <FontAwesomeIcon icon={faLinux} /> },
];

const Creators = [
  "Michael Reder",
  "Noa Elias",
  "Yoav Laugie",
  "Dylan Fleury",
  "Ury Yudkin",
  "Danel Yehuda",
];

class BlueRuleMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      blueRules: [],
      expandedCards: {},
      contentHeight: {},
      selectedRule: null, // Track the selected rule for detailed view
      platformFilter: "", // Track the selected platform filter
      creatorFilter: "",  // Track the selected creator filter
    };
    this.togglePlatformFilter = this.togglePlatformFilter.bind(this);
    this.handleCreatorChange = this.handleCreatorChange.bind(this);
  }

  async componentDidMount() {
    try {
      const blueRules = await sdk.blueRules.get({ include: ["blueRulesPlatforms","sysmon","cortex"] });
      console.log(blueRules);
      
      this.setState({ blueRules, loading: false });
    } catch (error) {
      console.error("Error fetching BlueRules:", error);
      this.setState({ loading: false });
    }
  }

  componentDidUpdate() {
    this.measureContentHeights();
  }

  measureContentHeights = () => {
    const { contentHeight } = this.state;
    this.state.blueRules.forEach((rule) => {
      const element = document.getElementById(`description-${rule.id}`);
      if (element) {
        const scrollHeight = element.scrollHeight;
        if (contentHeight[rule.id] !== scrollHeight) {
          this.setState((prevState) => ({
            contentHeight: {
              ...prevState.contentHeight,
              [rule.id]: scrollHeight,
            },
          }));
        }
      }
    });
  };

  toggleExpandCard = (id) => {
    this.setState((prevState) => ({
      expandedCards: {
        ...prevState.expandedCards,
        [id]: !prevState.expandedCards[id],
      },
    }));
  };

  renderPlatforms = (platforms) => {
    console.log(platforms);
    
    const activePlatforms = Object.keys(platforms[0])
      .filter((key) => platforms[0][key] === true && key !== "blueRuleId" && key !== "id")
      .map((key) => {
        const platformObj = Platforms.find((p) => p.key === key);
        return platformObj ? platformObj.platform : null;
      })
      .filter((icon) => icon !== null);

    return activePlatforms.map((icon, index) => (
      <div key={index} style={{ marginLeft: 8, fontSize: "35px" }}>
        {icon}
      </div>
    ));
  };

  handleCardClick = (rule) => {
    this.setState({ selectedRule: rule }); // Set the selected rule for detailed view
  };

  closeBlueRule = () => {
    this.setState({ selectedRule: null }); // Reset the selected rule
  };

  togglePlatformFilter(platformKey) {
    this.setState((prevState) => ({
      platformFilter: prevState.platformFilter === platformKey ? "" : platformKey,
    }));
  }

  handleCreatorChange(event) {
    this.setState({ creatorFilter: event.target.value });
  }

  filterByPlatform(rule) {
    if (!this.state.platformFilter) return true; // If no platform filter selected, show all
    const platforms = rule.blueRulesPlatforms[0];
    return platforms && platforms[this.state.platformFilter] === true;
  }

  filterByCreator(rule) {
    if (!this.state.creatorFilter) return true; // If no creator filter selected, show all
    return rule.creator === this.state.creatorFilter;
  }

  render() {
    const { loading, blueRules, expandedCards, contentHeight, selectedRule, platformFilter, creatorFilter } = this.state;

    const filteredBlueRules = blueRules.filter((rule) => this.filterByPlatform(rule) && this.filterByCreator(rule));

    return (
      <div style={{ display: "flex", height: "100vh" }}>
        {selectedRule && (
          <div
            style={{
              flex: 1,
              overflowY: "auto",
            }}
          >
            {/* Render the selected BlueRule */}
            <BlueRule rule={selectedRule} closeFunc={this.closeBlueRule} />
          </div>
        )}
        <div
          style={{
            flex: selectedRule ? 1 : 2,
            overflowY: "auto",
            padding: "20px",
          }}
        >
          {/* Platform Filter Buttons */}
          <div className="filter-container mb-3" style={{ marginBottom: "20px" }}>
            <h3 className="font-light pt-1 mr-1" style={{ color: "white" }}>
              Platforms
            </h3>
            <ButtonGroup>
              {Platforms.map((platform) => (
                <Button
                  key={platform.key}
                  color="primary"
                  style={{ color: "white" }}
                  active={platformFilter === platform.key}
                  onClick={() => this.togglePlatformFilter(platform.key)}
                >
                  {platform.name}
                </Button>
              ))}
            </ButtonGroup>
          </div>

          {/* Creator Filter Dropdown */}
          <div className="filter-container mb-3" style={{ marginBottom: "20px" }}>
            <h3 for="creatorFilter" style={{ color: "white", marginRight: "10px" }}>
              Creator:
            </h3>
            <select
              id="creatorFilter"
              className="form-control"
              value={creatorFilter}
              onChange={this.handleCreatorChange}
              style={{ maxWidth: "200px" }}
            >
              <option value="">All</option>
              {Creators.map((creator) => (
                <option key={creator} value={creator}>
                  {creator}
                </option>
              ))}
            </select>
          </div>

          {loading ? (
            <div className="text-center w-100">
              <i className="fas fa-spin fa-spinner" style={{ fontSize: "24px" }} />
            </div>
          ) : filteredBlueRules.length === 0 ? (
            <h1 className="text-center w-100">No Blue Rules</h1>
          ) : (
            <Row>
              {filteredBlueRules.map((rule) => {
                const shouldShowExpand = contentHeight[rule.id] && contentHeight[rule.id] > 50;

                return (
                  <Col lg="6" key={rule.id} className="mb-4">
                    <Card
                      style={{
                        boxShadow: "2px 3px 1px rgba(0,0,0,0.2)",
                        backgroundColor: "#424953",
                        color: "white",
                        position: "relative",
                        cursor: "pointer",
                      }}
                      onClick={() => this.handleCardClick(rule)} // Open BlueRule on click
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          padding: "10px",
                        }}
                      >
                        <div>
                          <CardTitle tag="h3">{rule.name}</CardTitle>
                          <CardSubtitle tag="h5">
                            Category: {rule.ruleCategory}
                          </CardSubtitle>
                          <p style={{ margin: 0 }}>Creator: {rule.creator}</p>
                        </div>
                        <div style={{ display: "flex", flexWrap: "nowrap" }}>
                          {this.renderPlatforms(rule.blueRulesPlatforms)}
                        </div>
                      </div>
                      <div
                        id={`description-${rule.id}`}
                        style={{
                          marginBottom: "30px",
                          padding: "10px",
                          maxHeight: expandedCards[rule.id] ? "none" : "50px",
                          overflow: "hidden",
                          transition: "max-height 0.5s ease",
                        }}
                      >
                        {rule.description}
                      </div>
                      {shouldShowExpand && (
                        <div
                          style={{
                            textAlign: "center",
                            cursor: "pointer",
                            padding: "5px",
                          }}
                          onClick={(e) => {
                            e.stopPropagation(); // Prevent triggering card click
                            this.toggleExpandCard(rule.id);
                          }}
                        >
                          <i
                            className={`fas fa-chevron-${
                              expandedCards[rule.id] ? "up" : "down"
                            }`}
                          ></i>
                        </div>
                      )}
                    </Card>
                  </Col>
                );
              })}
            </Row>
          )}
        </div>
      </div>
    );
  }
}

export default BlueRuleMenu;
