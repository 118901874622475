import React from "react";
import { FormGroup, Label, Input, Button, Alert } from "reactstrap";
import * as xml2js from "xml2js";

class AddSysmon extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            xmlText: "",
            isValidXml: null,
        };

        this.validateXml = this.validateXml.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(e) {
        this.setState({ xmlText: e.target.value, isValidXml: null });
    }

    validateXml() {
        const { xmlText } = this.state;
        xml2js.parseString(xmlText, (err) => {
            const isValidXml = !err;
            this.setState({ isValidXml });

            // Pass the validated XML to the parent
            if (isValidXml && this.props.onSysmonChange) {
                this.props.onSysmonChange(xmlText);
            }
        });
    }

    render() {
        return (
            <div>
                <FormGroup>
                    <Label for="sysmonXml">Enter Sysmon XML:</Label>
                    <Input
                        type="textarea"
                        id="sysmonXml"
                        rows="10"
                        value={this.state.xmlText}
                        onChange={this.handleChange}
                    />
                </FormGroup>
                <Button color="primary" onClick={this.validateXml}>
                    Validate XML
                </Button>
                {this.state.isValidXml !== null && (
                    <Alert color={this.state.isValidXml ? "success" : "danger"} className="mt-3">
                        {this.state.isValidXml ? "Valid XML" : "Invalid XML"}
                    </Alert>
                )}
            </div>
        );
    }
}

export default AddSysmon;
