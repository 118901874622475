import React, { useState, useEffect, useMemo } from 'react';
import { Row, Col, Button, Container, Card, CardBody, CardTitle } from 'reactstrap';
import sdk from 'api-sdk';
import ScenarioCard from '../reportForge/scenarioCard';
import PizZip from 'pizzip';
import Docxtemplater from 'docxtemplater';
import ImageModule from 'docxtemplater-image-module-free';
import { saveAs } from 'file-saver';
import englishTemplate from '../reportForge/englishTemplate.docx';
import SearchBar from '../../components/inputs/searchBar';
import { debounce } from 'lodash';

const AllScenarios = () => {
  // State Variables
  const [scenarios, setScenarios] = useState([]);
  const [selectedScenarios, setSelectedScenarios] = useState([]);
  const [loading, setLoading] = useState(false);
  const [, setPayloads] = useState([]);  // not currently used, but stored if you want
  const [, setScenariosWithImages] = useState([]);
  const [passedPayloadsCount, setPassedPayloadsCount] = useState(0);
  const [firstDefenceRules] = useState('No defence rules available');
  const [searchFilter, setSearchFilter] = useState('');
  const [filteredScenarios, setFilteredScenarios] = useState([]);

  // Debounced Search Handler
  const debouncedSetSearchFilter = useMemo(() => debounce(setSearchFilter, 300), []);

  // Cleanup debounce on unmount
  useEffect(() => {
    return () => {
      debouncedSetSearchFilter.cancel();
    };
  }, [debouncedSetSearchFilter]);

  // Fetch Scenarios on Mount
  useEffect(() => {
    let isMounted = true;
    fetchAllScenarios(isMounted);
    return () => {
      isMounted = false;
    };
  }, []);

  const fetchAllScenarios = async (isMounted) => {
    if (!isMounted) return;
    setLoading(true);
    try {
      const response = await sdk.scenario.get({});

      // Extract scenario data from response
      const scenariosData = response ? response : [];

      if (isMounted) {
        setScenarios(scenariosData);
        setFilteredScenarios(scenariosData);
      }
    } catch (error) {
      console.error('Error fetching scenarios:', error);
    } finally {
      setLoading(false);
    }
  };

  // Filter scenarios when searchFilter changes
  useEffect(() => {
    if (!searchFilter) {
      setFilteredScenarios(scenarios);
    } else {
      const lowerCaseQuery = searchFilter.toLowerCase();
      const filtered = scenarios.filter((scenario) =>
        scenario.silverBulletName.toLowerCase().includes(lowerCaseQuery)
      );
      setFilteredScenarios(filtered);
    }
  }, [searchFilter, scenarios]);

  const handleSearchChange = (e) => {
    const query = e.target.value;
    debouncedSetSearchFilter(query);
  };

  const generateReport = async () => {
    if (selectedScenarios.length === 0) {
      alert('Please select at least one scenario to generate the report.');
      return;
    }

    setLoading(true);
    try {
      let rowCounter = 1;

      const enrichedScenarios = await Promise.all(
        selectedScenarios.map(async (scenario) => {
          const payloadsForScenario = await sdk.silverBullets.getPayloads(scenario.silverBulletId);
          const response = await sdk.silverBullets.getDefRuleIds(scenario.silverBulletId);
          const defRuleIds = response.defRuleIds;

          let recommendations = [];
          if (Array.isArray(defRuleIds)) {
            recommendations = await Promise.all(
              defRuleIds.map(async (defRuleId) => {
                // 1) Fetch the defRuleText from the new getDefRuleText
                const defRuleText = await sdk.defRules.getDefRuleText(defRuleId);

                // 2) The method now returns { systemName, mitigations } directly.
                //    So we just check them directly:
                const systemName = defRuleText.systemName || 'Unknown';

                // 3) If "mitigations" is a string, but we need an array, we can do:
                const mitigations = Array.isArray(defRuleText.mitigations)
                  ? defRuleText.mitigations
                  : [defRuleText.mitigations].filter(Boolean);

                let paragraph = 'No paragraph available';

                // 4) If we have mitigations, call getParagraphByTitle
                if (mitigations.length > 0) {
                  try {
                    const response = await sdk.defRules.getParagraphByTitle(defRuleId, 'Details of the attack');
                    paragraph = response.paragraph || 'No paragraph available';
                  } catch (error) {
                    console.error(`Error fetching paragraph for defRuleId ${defRuleId}`, error);
                  }
                }

                return { systemName, mitigations, paragraph };
              })
            );

          }

          const payloadsData = payloadsForScenario.map((payload) => ({
            rowNumber: rowCounter++,
            status: 'Pass',
            categoryName: 'Category Name',
            details: payload.details || '',
            fileFormat: payload.fileFormat,
            scenarioName: scenario.silverBulletName,
            pass: true,
            fail: false,
          }));

          return {
            ...scenario,
            payloads: payloadsData,
            recommendations,
          };
        })
      );

      const allPayloads = enrichedScenarios
        .flatMap((scenario) => scenario.payloads)
        .sort((a, b) => {
          if (a.pass && !b.pass) return -1;
          if (!a.pass && b.pass) return 1;
          return 0;
        });

      setPayloads(allPayloads);
      setPassedPayloadsCount(allPayloads.filter((p) => p.pass).length);

      const scenariosWithImagesData = await Promise.all(
        enrichedScenarios.map(async (scenario) => {
          const { base64Chart } = await sdk.silverBullets.getChartBase64(scenario.silverBulletId);
          return { ...scenario, chartImage: base64Chart };
        })
      );

      setScenariosWithImages(scenariosWithImagesData);

      // Now build the docx using Docxtemplater
      const templateResponse = await fetch(englishTemplate);
      const templateArrayBuffer = await templateResponse.arrayBuffer();
      const zip = new PizZip(templateArrayBuffer);

      const doc = new Docxtemplater().attachModule(
        new ImageModule({
          centered: true,
          getImage: (tagValue) =>
            new Uint8Array(
              atob(tagValue)
                .split('')
                .map((char) => char.charCodeAt(0))
            ),
          getSize: () => [616, 148],
        })
      ).loadZip(zip);

      const formattedDate = new Date().toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      });

      doc.setData({
        runNumber: '001',
        date: formattedDate,
        scenarios: scenariosWithImagesData.map((scenario, index) => {
          const isH = scenario.scenarioDifficulty === 'High';
          const isM = scenario.scenarioDifficulty === 'Medium';
          const isL = scenario.scenarioDifficulty === 'Low';
          const ImpactH = scenario.scenarioImpact === 'High';
          const ImpactM = scenario.scenarioImpact === 'Medium';
          const ImpactL = scenario.scenarioImpact === 'Low';

          return {
            ...scenario,
            scenarioNumber: index + 1,
            scenarioTitle: scenario.silverBulletName,
            scenarioDescription: scenario.description,
            attackFlow: [{ imageTag: scenario.chartImage }],
            recommendations: scenario.recommendations,
            isH,
            isM,
            isL,
            ImpactH,
            ImpactM,
            ImpactL,
          };
        }),
        payloads: allPayloads,
        passedPayloadsCount,
        firstDefenceRules,
      });

      doc.render();
      const output = doc.getZip().generate({ type: 'blob' });
      saveAs(output, `Scenario_Report_${formattedDate}.docx`);
    } catch (error) {
      console.error('Error generating report:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container className="mt-5">
      <Row className="justify-content-center">
        <Col md={6}>
          <Card className="shadow-sm border-0">
            <CardBody>
              <CardTitle tag="h4" className="text-center mb-4">Scenario Report</CardTitle>
              <Button
                color="success"
                block
                onClick={generateReport}
                disabled={selectedScenarios.length === 0}
              >
                Generate Report ({selectedScenarios.length} selected)
              </Button>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row className="mt-5">
        <Col>
          {/* Search Bar */}
          <div className="mb-4">
            <SearchBar
              className="mb-3"
              onChange={handleSearchChange}
              isLoading={loading}
            />
          </div>

          {loading ? (
            <p>Loading scenarios...</p>
          ) : (
            <>
              {filteredScenarios.length === 0 ? (
                <p>No scenarios found.</p>
              ) : (
                <Row>
                  {filteredScenarios.map((scenario) => (
                    <Col className="mb-4" md={6} lg={4} key={scenario.silverBulletId}>
                      <ScenarioCard
                        scenario={scenario}
                        // Check if selected
                        isSelected={selectedScenarios.some(
                          (s) => s.silverBulletId === scenario.silverBulletId
                        )}
                        // Toggle selection
                        onSelect={(clickedScenario) =>
                          setSelectedScenarios((prev) =>
                            prev.some(
                              (s) => s.silverBulletId === clickedScenario.silverBulletId
                            )
                              ? // if already selected -> unselect
                              prev.filter(
                                (s) => s.silverBulletId !== clickedScenario.silverBulletId
                              )
                              : // otherwise -> add it to the array
                              [...prev, clickedScenario]
                          )
                        }
                      />
                    </Col>
                  ))}
                </Row>
              )}
            </>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default AllScenarios;
