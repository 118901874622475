import React from "react";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Table,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import DeleteButton from '../../components/buttons/deleteButton';
import sdk from 'api-sdk';
import SearchBar from '../../views/silverBulletManagement/searchBar';
import '../../views/silverBulletManagement/SearchBar.css';
import { toast } from 'react-toastify';

class BlueRuleTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      blueRules: null,
      search: null,
    };

    this.getBlueRules = this.getBlueRules.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.searchFunction = this.searchFunction.bind(this);
    this.setSearch = this.setSearch.bind(this);
  }

  getBlueRules() {
    sdk.blueRules.get().then(blueRules => {
      this.setState({ blueRules });
    });
  }

  handleDelete(id, name) {
    sdk.blueRules.delete(id).then(() => {
      this.getBlueRules(); // Refresh the list of blue rules
      toast.success(`Blue Rule "${name}" deleted successfully`); // Show success toast
    }).catch(error => {
      console.error("Error deleting blue rule:", error);
      toast.error("Error deleting blue rule"); // Show error toast in case of failure
    });
  }

  componentDidMount() {
    this.getBlueRules();
  }

  searchFunction(blueRule) {
    if (!this.state.search) return true;
    return blueRule.name.toLowerCase().includes(this.state.search.toLowerCase());
  }

  setSearch(searchTerm) {
    this.setState({ search : searchTerm });
    return searchTerm;
  }

  render() {
    const currentBlueRules = this.state.blueRules ? this.state.blueRules.filter(br => this.searchFunction(br)) : null;

    return (
      <Card>
        <div className="search-bar-container">
          <SearchBar searchFunction={this.setSearch} />
        </div>
        <CardTitle className="bg-light border-bottom p-3 mb-0">
          Blue Rule Management
          <span className="float-right">
            <Button className='btn-outline-secondary mr-4' size='sm' onClick={() => sdk.blueRules.downloadReport('pdf')}>
              Download report
            </Button>
            <Link to={`blue-rule-management/create`} onClick={e => e.stopPropagation()}>
              <Button className='btn-outline-secondary' size='sm'>
                Create Blue Rule
              </Button>
            </Link>
          </span>
        </CardTitle>
        <CardBody className='p-0'>
          {this.state.blueRules?
            <Table hover>
              <thead>
                <tr>
                  <th>Id</th>
                  <th>Name</th>
                  <th>Rule Category</th>
                  <th>Created</th>
                  <th>Edit</th>
                  <th>Delete</th>
                </tr>
              </thead>
              <tbody>
              {
                this.state.blueRules.length === 0?
                  <tr key='no-bluerule'>
                    <td colSpan='6'>
                      <h3>
                        No Blue Rules
                      </h3>
                    </td>
                  </tr>
              : null}
              {
                currentBlueRules && currentBlueRules.map((blueRule) => (
                  <tr key={blueRule.id}>
                    <td>{blueRule.id}</td>
                    <td>{blueRule.name}</td>
                    <td>{blueRule.ruleCategory}</td>
                    <td>{(new Date(Date.parse(blueRule.createdAt))).toLocaleString('en-il')}</td>
                    <td>
                      <Link to={`blue-rule-management/edit/${blueRule.id}`} onClick={e => e.stopPropagation()}>
                        <Button className='btn-outline-secondary'>
                          <i className='mdi mdi-pencil'/>
                        </Button>
                      </Link>
                    </td>
                    <td>
                      <DeleteButton item='blue rule' deleteFunc={() => {this.handleDelete(blueRule.id, blueRule.name)}}/>
                    </td>
                  </tr>
                ))
              }
              </tbody>
            </Table>
          :
            <h1 className='text-center'><i className='fas fa-spin fa-spinner'/></h1>
          }
        </CardBody>
      </Card>
    );
  }
}

export default BlueRuleTable;
